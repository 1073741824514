import React from 'react';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import { LocaleContext } from "../../layout/layout";
import locales from "../../../config/i18n";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    switchLang: {
        cursor: "pointer",
        zIndex: "50",
        "&:hover $langDropdown": {
            display: "block",
            position: "fixed",
        }
    },
    typography: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "20px",
        }
    },
    currentLang: {
        padding: "5px",
    },
    langDropdown: {
        display: "none",
        background: "white",
        borderRadius: "5px",
    },
    selectingLang: {
        padding: "5px",
        cursor: "pointer",
        "&:hover": {
            fontWeight: "bold",
            background: grey[300],
            "&:first-child": {
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px"
            },
            "&:last-child": {
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px"
            }
        }
    },
    langText: {
        textDecoration: "none",
        "&:visited": {
            color: "black"
        }
    }
}));

const  Language = (props) => {
    const { locale } = React.useContext(LocaleContext);

    const classes = useStyles();
    return (
        <Box className={props.className} display="flex" alignItems="center">
            <Box className={classes.switchLang} display="flex" alignItems="center" flexDirection="column">
                <Box className={classes.currentLang}>
                    <Typography className={classes.typography}>
                        {locales[locale].siteLanguage}
                    </Typography>
                </Box>
                <Box className={classes.langDropdown} boxShadow={3}>
                    {Object.keys(locales).map(language => (
                    <Box  
                        key={language}
                        className={classes.selectingLang}>
                        <Link 
                            className={classes.langText} 
                            to={locales[language].default ? "/" : `/${language}`} hrefLang={language}>
                            <Typography className={classes.typography}>{locales[language].siteLanguage}</Typography>
                        </Link>
                    </Box >
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default Language;
