import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: "pointer"
    },
    typography: {
        letterSpacing: "0.1em",
        [theme.breakpoints.down('md')]: {
            fontSize: "4vw",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "4rem",
        }
    },
    white: {
        color: "white"
    },
    parenthesis: {
        color: theme.palette.secondary.main,
    },
}));

const  CodeckLogo = (props) => {
    const classes = useStyles();

    const handleClick = () => {
        props.onClick();
    }

    return (
        <Box className={classes.root} alignSelf="center" onClick={handleClick}>
            <Typography className={classes.typography}>
                <code className={classes.parenthesis}>{`{`}</code>
                <code className={clsx(!props.scroll && classes.white)}>{`codeck`}</code>
                <code className={classes.parenthesis}>{`}`}</code>
            </Typography>
        </Box>
    );
};

export default CodeckLogo;