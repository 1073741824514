// Only one item MUST have the "default: true" key

module.exports = {
    en: {
		default: true,
		path: `en`,
		languageName: `English`,
		locale: `en-US`,
		dateFormat: `MM/DD/YYYY`,
		siteLanguage: `en`,
		ogLanguage: `en_US`
    },
    fr: {
		path: `fr`,
		languageName: `Français`,
		locale: `fr-FR`,
		dateFormat: `DD/MM/YYYY`,
		siteLanguage: `fr`,
		ogLanguage: `fr_FR`
    },
  }
