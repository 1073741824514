import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  text: {
    color: 'white'
  }
}));

const Copyright = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.text} variant="body2">
      {`Copyright © codeck ${new Date().getFullYear()}`}
    </Typography>
  );
};

export default Copyright;
