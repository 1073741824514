import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Copyright from '../copyright/copyright';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    background: '#333',
    minHeight: '200px',
    width: '100%',
  }
}));

function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Copyright />
      <IconButton component={Link} href="https://github.com/codeck-ch" target="_blank" title="GitHub">
        <GitHubIcon fontSize="small" style={{ color: "white" }} />
      </IconButton>
    </footer>
  );
}

export default Footer;
