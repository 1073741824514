import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CodeckLogo from '../codeckLogo/codeckLogo';
import Box from '@material-ui/core/Box';
import Language from '../language/language';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Typography from '@material-ui/core/Typography';
import { nanoid } from 'nanoid';
import useTranslations from "../../_customHooks/useTranslations";

const useStyles = makeStyles(theme => ({
    appBarTop: {
        zIndex: 5,
        overflow: "hidden",
        position: "fixed",
        top: 0,
        height: "auto",
        width: "100%",
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    appBarScroll: {
        backgroundColor: 'white',
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
    },
    menu: {
        cursor: "pointer",
        marginLeft: "6vw",
    },
    item: {
        flexShrink: 0,
        "&:hover": {
            color: theme.palette.secondary.main,
            fontWeight: 600
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "20px",
        }
    },
    active: {
        color: theme.palette.secondary.main,
        fontWeight: 600
    },
    lang: {
        marginRight: theme.spacing(1)
    },
    topAnchor: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

const  CodeckAppBar = (props) => {
    const { nav } = useTranslations()
    const classes = useStyles();
    const [scroll, setScroll] = useState(false);
    const [active, setActive] = useState(-1);
    const [menu, setMenu] = useState();

    useEffect(() => {
        const handleScroll = () => setScroll(window.pageYOffset >= 30);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    useEffect(() => {
        setMenu([
            {
                id: nanoid(),
                name: nav.who,
                scrollTo: "#who"
            },
            {
                id: nanoid(),
                name: nav.service,
                scrollTo: "#service"
            },
            {
                id: nanoid(),
                name: nav.contact,
                scrollTo: "#contact"        
            },
        ]);
    }, [nav]);

    const handleLogoClick = () => {
        scrollTo("#back-to-top-anchor");
        setActive(-1)
    };

    const handleItemClick = (item) => {
        const index = menu.findIndex(menuItem => menuItem.id === item.id);
        scrollTo(item.scrollTo);
        setActive(index);
    };

    return (
        <React.Fragment>
            <Box id="back-to-top-anchor" />
            <Box display="flex" className={`${classes.appBarTop} ${scroll && classes.appBarScroll}`}>
                <Box display="flex" width="20vw">
                    <CodeckLogo scroll={scroll} onClick={handleLogoClick}></CodeckLogo>
                </Box>
                <Box display="flex" width="60vw" justifyContent="flex-end">
                    {menu && menu.map((item, index) => (
                        <Box 
                            key={item.id}
                            className={classes.menu} 
                            onClick={() => handleItemClick(item)} 
                            display="flex" 
                            alignItems="center">
                        <Typography className={clsx(classes.item, active === index && classes.active)}>{item.name}</Typography>
                    </Box >
                    ))}
                </Box>
                <Box display="flex" width="20vw" justifyContent="flex-end">
                    <Language className={classes.lang} />
                </Box>
            </Box>
            <Zoom in={scroll}>
                <Box onClick={handleLogoClick} role="presentation" className={classes.topAnchor}>
                    <Fab color="secondary" aria-label="scroll back to top">
                        <ArrowUpwardIcon />
                    </Fab>
                </Box>
            </Zoom>
        </React.Fragment>
    );
}

export default CodeckAppBar;
