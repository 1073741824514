import { createMuiTheme } from '@material-ui/core/styles';
import '@fontsource/roboto';

const defaultCodeckTheme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          margin: '0 !important',
          WebkitFontSmoothing: 'antialiased',
          backgroundColor: 'white'
        }
      }
    }
  },
  spacing: factor => `${1 * factor}rem`,
  typography: {
    fontFamily: 'Roboto'
  },
  palette: {
    primary: { main: '#333', contrastText: '#fff' },
    secondary: { main: '#da291c', contrastText: '#fff' }
  }
});

// 20AD92 commercetools color 

export default defaultCodeckTheme;
