import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import CodeckAppBar from '../components/codeckAppBar/codeckAppBar';
import Footer from '../components/footer/footer';
import { Helmet } from 'react-helmet';

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale }, props }) => {

  return (
	<LocaleContext.Provider value={{ locale }}>
		<Helmet>
			<title>Codeck</title>
			<meta name="description" content="codeck is passionate and experimented bunch of coders, all freelancers. Software architecture consultant" />
		</Helmet>
		<CssBaseline />
		<CodeckAppBar></CodeckAppBar>
		{children}
		<Footer></Footer>
	</LocaleContext.Provider>
  );
};

export { Layout, LocaleContext }
